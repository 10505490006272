import React from "react";

export default class Canvas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };

        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("message", this.onReceiveMessage, false);
    }

    shouldComponentUpdate() {
        return false;
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onReceiveMessage);
    }

    onReceiveMessage = (event) => {
        console.log('onReceiveMessage: ', event.data);
        this.props.onMessage(true);
    }

    render() {
        return <iframe ref={this.canvasRef} onLoad={this.onLoad} title="game" src={`${process.env.REACT_APP_CANVASPATH}/web-mobile/`}></iframe>;
    }

}