import React, { useState } from "react";
import Canvas from './Canvas';
import Overlay from './Overlay';

console.log(`Starting App.js (ENV: ${process.env.NODE_ENV})`);

function App() {
  const [clicked, setClicked] = useState(false);

  return (
    <div>
      <Canvas onMessage={setClicked} />
      {
        clicked && <Overlay />
      }
    </div>
  );
}

export default App;