import React from "react";

export default class Overlay extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    onReceiveMessage(event) {
        
    }


    render() {
        return (
            <div id="overlay">
                hello
            </div>
        )
    }

}